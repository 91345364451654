<template>
	<div class="main">
		<wsHeader />
		<div class="page"><router-view></router-view></div>
		<wsFooter />
	</div>
</template>

<script>
import wsHeader from '@/components/wsHeader.vue';
import wsFooter from '@/components/wsFooter.vue';
export default {
	name: 'layout',
	components: { wsHeader, wsFooter }
};
</script>

<style lang="less" scoped>
.main {
	height: 100%;
	display: flex;
	flex-direction: column;
	.page {
		padding-top: 65px;
		flex: 1;
		box-sizing: border-box;
	}
}
</style>
