export default {
    frontPage: "首页",
    allLive: "全部直播",
    schedules: "赛程",
    app: "APP下载",
    startLive: "开始直播",
    login: "登录",
    register: "注册",
    logout: "退出登录",
    reset: "重置",
    personalCenter: "个人中心",
    myFollows: "我的关注",
    myAppointments: "我的预约",
    myFeedbacks: "我的反馈",
    logoutConfirm: "确定要退出登录吗？",
    signOff: "退出",
    cancel: "取消",
    typeInPW: "请输入密码",
    // wsFooter.vue
    disclaimer: "免责声明",
    about06Live: "关于06live",
    Live06: "06直播",
    // wsScheduleTable.vue
    contests_name: "赛事/名称",
    homeTeam: "主队",
    guestTeam: "客队",
    streamer: "主播",
    notStarted: "未开始",
    finished: "已完赛",
    living: "进行中",
    booked: "已预约",
    reservation: "预约",
    football: "足球",
    basketball: "篮球",
    rest: "其他",
    tennis: "网球",
    volleyball: "排球",
    billiards: "台球",
    badminton: "羽毛球",
    e_sports: "电竞",
    // wsAuthorized.vue
    retrievePassword: "找回密码",
    toLogin: "返回登录",
    registerAccounts: "注册账号",
    loginByAccounts: "账号登录",
    loginByCodes: "验证码登录",
    phoneText: "请输入手机号码",
    passwordText: "请输入登录密码",
    newPasswordText: "请输入新的登录密码",
    loginConfirmPsw: '请再次确认密码',
    picturesText: "请输入图片验证码",
    codeText: "请输入验证码",
    getCodeText: "获取验证码",
    timeReminder: "s后重新获取",
    forgetPassword: "忘记密码",
    agreement: "同意",
    registerAgreement: "《用户注册协议》",
    newRegister: "新用户注册",
    phoneFormatReminder: "手机号码格式不正确",
    agreementReadReminder: "请阅读并同意用户注册协议",
    phoneNicknameReminder: "请输入昵称或手机号码",
    passwordMatchReminder: "两次输入密码不一致",
    codeFormatReminder: "请输入4位数字验证码",
    //wsChatroom.vue
    systemMSG: "系统消息：",
    associate: "助理",
    manager: "管理员",
    customer: "客服",
    ordinary: "普通",
    confirmSilent: "是否禁言用户",
    silent: "禁言",
    chatBoxText: "请输入聊天内容~",
    systemMaintenance: "系统维护中",
    sendMSGtooFast: "客官,说话太快啦(つД`)",
    failToSendMSG: "消息发送失败",
    succeedToOperate: "操作成功",
    send: "发送",
    newMessages: '有新的消息',
    //userInfo.vue
    nickname: "昵称:",
    nicknameText: "请输入昵称",
    phoneNumber: "手机号码:",
    personalIntro: "个人简介:",
    maxLength: "最多可输入200字",
    saveSubmission: "保存",
    changeAvatar: "修改头像",
    uploading: "正在上传...",
    uploaded: "上传成功!",
    maxLengthNickname: "昵称最多12个字",
    changed: "修改成功",
    uploadImage: "请上传图片(png,jpg,jpeg)",
    uploadImgLimit: "请不要上传超过2MB!",
    noSign: '这个人很懒，什么都不留下~',
    //UserFollow.vue
    unFollow: "取消关注",
    onfollow: '关注主播',
    streaming: "直播中",
    unFollowReminder: "取消关注后，将不会收到主播开播提醒，确定取消吗？",
    thinkAgain: "我再想想",
    //UserFeedbackCreate.vue UserFeedback.vue
    feedbackContent: "反馈内容",
    feedbackText: "您的反馈将帮助我们更快成长，请尽量详细描述",
    uploadIMG: "图片上传",
    connection: "联系方式",
    connectionReminder: "手机号码、facebook、telegram均可",
    submit: "提交",
    submitted: "提交成功",
    feedbackReminder: "请填写反馈内容",
    feedbackImg: "反馈图片",
    Nothing: "无",
    //UserAppointment.vue User.vue
    madeAnAppointment: '预约成功',
    //date: 'MM月DD日',
    myFiles: '我的资料',
    toFeedback: '去反馈',
    //Schedules.vue
    finishedList: '完赛',
    noLiveYet: '暂无直播',
    //Lives.vue Live.vue Reply.vue
    comprehensive: '综合',
    PremierLeague: '英超',
    LaLiga: '西甲',
    SerieA: '意甲',
    Bundesliga: '德甲',
    FrenchLeague: '法甲',
    ChineseSuperLeague: '中超',
    ChampionsLeague: '欧冠杯',
    AFCChampionsLeagueCup: '亚冠杯',
    follow: '关注',
    scan: '扫一扫',
    addStreamers: '加主播好友',
    streamerSchedules: '主播日程',
    videoRecommends: '视频推介',
    specialClaims: '特别声明：',
    specialClaimsContent1: '06live是一个综合性体育赛事直播平台，我们所有的赛事直播源均由第三方提供，我们不提供任何独家赛事直播视频，不制作任何赛事直播数据。',
    specialClaimsContent2: '请各位网友遵守当地国家法律法规，请勿用于非法用途，如有疑问请与我们取得联系。',
    //Home.vue
    enterLiveRoom: '进入直播间',
    hotLiving: '正在热播',
    checkMore: '查看更多',
    hotStreamers: '热门主播',
    footballLiving: '足球直播',
    basketballLiving: '篮球直播',
    comprehensiveLiving: '综合直播',
    enterLivingRoom: '进入直播间',
    //App.vue
    goToScan: '去扫码下载APP',
    adContent1: '互动零距离，畅想聊天时刻。',
    adContent2: '语音更要交友，聊天不止快乐。',
    adContent3: '女神在线直播、小鲜肉、萌妹子、魅力大叔，',
    adContent4: '全天24小时不间断！给你带来最专业的解说。',
    adContent5: '大量超清体育节目，实时播放，',
    adContent6: '专业分析员在线解说赛事。',
    //About.vue
    appIntro1: '919直播（tyzb.live）是一家专业提供足球、篮球赛事视频直播的体育直播平台。',
    appIntro2: '919直播致力不断提升用户体验，努力打造成为国内顶尖的足球、篮球数据平台，为振兴国内足球、篮球事业贡献一份力量。',
    appIntro3: '919直播为您提供',
    appIntro4: '稳定，领先的热门视频解说服务；',
    appIntro5: '全面，高清的热门赛事视频直播；',
    appIntro6: '专业，客观的直播解说；',
    appIntro7: '详细，完整的数据服务；',
    //Privacy.vue Disclaimer.vue
    privacyAgreement: '隐私政策',
    privacyContent1: '本隐私政策介绍本公司的隐私数据相关政策和惯例，这将涵盖我们如何收集、使用、处理、存储和/或披露那些通过本公司的网站收集的关于您的个人信息。请你仔细阅读我们的隐私政策。',
    privacyContent2: '一、本公司如何收集您的个人信息',
    privacyContent3: '个人信息是可用于唯一地识别或联系某人的数据。',
    privacyContent4: '当您使用本公司的网站观看体育视频时，，注册用户过程中我们将会收集您的个人信息，如：电话号码。为了保护个人隐私，您不应提供除本公司特别要求之外的任何其它信息。',
    privacyContent5: '二、本公司如何使用您的个人信息',
    privacyContent6: '1、通过您的个人信息实现密码找回功能。',
    privacyContent7: '2、除本公司发生重组、合并或出售，可将我们收集的一切个人信息转让给相关第三方外，本公司不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本公司单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些信息。',
    privacyContent8: '三、个人信息安全',
    privacyContent9: '保证您的个人数据的安全对我们来说至关重要。当您在本公司的网站中注册输入个人数据时，我们会利用安全套接字层技术 (SSL) 对这些信息进行加密。',
    privacyContent10: '在数据传输和数据保管两个阶段里，我们会通过广为接受的行业标准（如防火墙、加密和数据隐私法律要求）来保护您向我们提交的信息。',
    privacyContent11: '然而，没有任何一种互联网传输或电子存储方法是100%安全的。因此，尽管我们通过商业上可接受的方式来保护您的个人信息，但仍无法保证信息的绝对安全。',
    privacyContent12: '四、本公司会将个人信息保存多久',
    privacyContent13: '一般来说，本公司仅保留您的个人信息至履行收集目的所需的期限，同时将遵守适用法律规定的数据保留期限。',
    privacyContent14: '五、法律免责声明',
    privacyContent15: '在法律要求的情况下，以及本公司认为必须披露与您有关的信息来保护本公司的法定权益和/或遵守司法程序、法院指令或适用于本公司的网站的法律程序时，我们有权透露您的个人信息。',
    privacyContent16: '如果本公司确定为了执行本公司的条款和条件或保护我们的经营，披露是合理必须的，则我们可披露与您有关的信息。',
    privacyContent17: '六、本隐私政策的更改',
    privacyContent18: '如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息。',
    privacyContent19: '本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站通知的形式告知。',
    disclaimerAndUserAgreement: '用户协议与免责声明',
    //news
    news:'资讯',
    released:'发布时间',
    all:"全部",
    //score
    score:'比分',
    footScore:'足球比分',
    basketballScore:'篮球比分',
    liveScore:'即时比分',
    FinishingScore:'完赛比分',
    FutureSchedule:'未来赛程',
    myCollection:'我的收藏',
    filter:'筛选',
    eventFilter:'赛事筛选',
    AllLeagues:'全部联赛',
    FiveMajorLeagues:'五大联赛',
    firstLeague:'一级联赛',
    selectAll:'全选',
    counterElection:'反选',
    sure:'确定',
    functionSelection:'功能选择',
    redAndYellow:'红黄牌显示',
    show:'显示',
    hide:'隐藏',
    league:'联赛',
    time:'时间',
    state:'状态',
    homeTeam:'主场球队',
    score:'比分',
    awayTeam:'客场球队',
    half:'半场',
    cornerKick:'角球',
    victoryOrDefeat:'胜负',
    handicap:'让球',
    goalNumber:'进球数',
    data:'数据',
    descant:'解说',
    live:'直播',
    operate:'操作',
    odds:'指数',
    inProgress:'进行中',
    haNotStarted:'未开始',
    over:'已结束',
    illustration:'图例说明',
    goal:'进球',
    penaltyKick:'点球',
    Oolong:'乌龙',
    redCard:'红牌',
    yellowCard:'黄牌',
    twoYellowsTurnoOneRed:'两黄变一红',
    substitution:'换人',
    animation:'动画',
    upper:'上',
    lower:'下',
    PointsDifference:'分差',
    totalScore:'总分',
    handicap:'让分',
    fullTime:'全场',
    RealtimeData:'实时数据',
    EuropeOdds:'欧洲指数',
    handicapOdds:'让球指数',
    sizeOdds:'大小指数',
    bookMatch:'预约赛程',
    notBooked:'暂无预约赛程',
    attack:'进攻',
    dangerAttack:'危险进攻',    
    possessionRate:'控球率',
    Shotontarget:'射正',
    Shotofftarget:'射偏',
    textLive:'文字直播',
    allShow:'全部显示',
    companies:'间公司',
    immediate:'即时',
    initial:'初始',
    timeChanges:'时间变化',
    allCompanies:'全部公司',
    homeWin:'主胜',
    awayWin:'客胜',
    draw:'平局',
    maxBall:'大球',
    minBall:'小球',
    team:'球队',
    Overtime:'加时',
    Section1:'第一节',
    Section2:'第二节',
    Section3:'第三节',
    Section4:'第四节',
    Finished:'完场',
    undetermined:'待定',
    interrupt:'中断',
    postpone:'推迟',
    midfielder:'中场',
    first:'上半场',
    second:'下半场',
    notyet:'未',
    noData: '暂无数据',
};
