export default {
    frontPage: "اصلی",
    allLive: "همه",
    video:"ویدئو",
    schedules: "برنامه",
    app: "دانلود APP",
    startLive: "شروع پخش زنده",
    login: "ورودر",
    register: "ثبت نام",
    logout: "خروج از سیستم",
    reset: "تنظیم مجدد",
    personalCenter: "مرکز شخصی",
    myFollows: "تمرکز من",
    myAppointments: "قرار من",
    myFeedbacks: "بازخورد من",
    logoutConfirm: "آیا برای خارج شدن مطمئن هستید؟",
    signOff: "ترک کردن",
    cancel: "لغو",
    typeInPW: "لطفا رمز عبور را وارد کنید",
    // wsFooter.vue
    disclaimer: "سلب مسئولیت",
    aboutIran_sports: "درباره ایران_ورزش",
    Iran_sports: "ایران_ورزش",
    // wsScheduleTable.vue
    contests_name: "نام رخداد",
    homeTeam: "تیم میزبان",
    guestTeam: "تیم خارج از خانه",
    streamer: "لنگر",
    notStarted: "شروع نشده است",
    finished: "تکمیل شد",
    living: "در حال پیش رفت",
    booked: "قبلا رزرو شده است",
    reservation: "ذخیره",
    football: "فوتبال",
    basketball: "بسکتبال",
    rest: "دیگر",
    tennis: "تنیس",
    volleyball: "والیبال",
    billiards: "بیلیارد",
    badminton: "بدمینتون",
    e_sports: "بازی",
    // wsAuthorized.vue
    retrievePassword: "بازیابی رمز عبور",
    toLogin: "بازگشت به ورود",
    registerAccounts: "ثبت حساب",
    loginByAccounts: "ورود به حساب کاربری",
    loginByCodes: "کد تایید ورود به سیستم",
    phoneText: "لطفا شماره تلفن را وارد کنید",
    passwordText: "لطفا رمز عبور خود را وارد کنید",
    newPasswordText: "لطفا رمز عبور جدید را وارد کنید",
    loginConfirmPsw: "لطفاً رمز عبور را دوباره تأیید کنید",
    picturesText: "لطفا کد تایید تصویر را وارد کنید",
    codeText: "لطفا کد تایید را وارد کنید",
    getCodeText: "دریافت کد تایید",
    timeReminder: "s بعد از دریافت مجدد",
    forgetPassword: "رمز عبور را فراموش کرده اید ؟",
    agreement: "موافق",
    registerAgreement: "توافقنامه ثبت نام کاربر",
    newRegister: "ثبت نام کاربر جدید",
    phoneFormatReminder: "قالب شماره تلفن همراه نادرست است",
    agreementReadReminder: "لطفا توافقنامه ثبت نام کاربر را بخوانید و با آن موافقت کنید",
    phoneNicknameReminder: "لطفا نام مستعار یا شماره تلفن همراه خود را وارد کنید",
    passwordMatchReminder: "رمز ورود دو بار مغایرت دارد",
    codeFormatReminder: "لطفاً کد تأیید 4 رقمی را وارد کنید",
    // wsChatroom.vue
    systemMSG: "اطلاعات سیستم",
    associate: "دستیار",
    manager: "مدیر",
    customer: "خدمات مشتری",
    ordinary: "معمولی",
    confirmSilent: "آیا کاربر را ممنوع می کند",
    silent: "ممنوع کردن",
    chatBoxText: "لطفا محتوای چت را وارد کنید~",
    systemMaintenance: "سیستم در حال تعمیر و نگهداری",
    sendMSGtooFast: "آقا شما خیلی سریع صحبت می کنید (つД`)",
    failToSendMSG: "ارسال پیام ناموفق بود",
    succeedToOperate: "عملیات موفقیت آمیز",
    send: "ارسال",
    newMessages: "خبر جدیدی هست",
    // userInfo.vue
    nickname: "نام مستعار",
    nicknameText: "لطفا یک نام مستعار وارد کنید",
    phoneNumber: "شماره تلفن",
    personalIntro: "پروفایل شخصی",
    maxLength: "شما می توانید تا 200 کاراکتر وارد کنید",
    saveSubmission: "صرفه جویی",
    changeAvatar: "آواتار را اصلاح کنید",
    uploading: "در حال آپلود...",
    uploaded: "آپلود با موفقیت انجام شد!",
    maxLengthNickname: "نام مستعار می تواند حداکثر 12 کاراکتر باشد",
    changed: "با موفقیت اصلاح شد",
    uploadImage: "لطفا تصاویر را آپلود کنید (png، jpg، jpeg)",
    uploadImgLimit: "لطفا بیش از 2 مگابایت آپلود نکنید!",
    noSign: "این شخص بسیار تنبل است و چیزی از خود باقی نمی گذارد~",
    // UserFollow.vue
    unFollow: "لغو اشتراک",
    onfollow: "لنگر را دنبال کنید",
    streaming: "پخش زنده",
    unFollowReminder: "پس از لغو دنبال کردن، یادآوری پخش مجری را دریافت نمی‌کنید آیا مطمئن هستید که لغو می‌کنید؟",
    thinkAgain: "بگذار دوباره فکر کنم",
    // UserFeedbackCreate.vue UserFeedback.vue
    feedbackContent: "محتوای بازخورد",
    feedback: "بازخورد",
    feedbackText: "نظرات شما به ما کمک می کند تا سریعتر رشد کنیم، لطفاً آن را تا حد امکان با جزئیات شرح دهید",
    uploadIMG: "آپلود عکس",
    connection: "اطلاعات تماس",
    connectionReminder: "شماره تلفن همراه، فیسبوک، تلگرام همگی قابل قبول هستند",
    submit: "ارسال",
    submitted: "با موفقیت ثبت شد",
    feedbackReminder: "لطفا محتوای بازخورد را پر کنید",
    feedbackImg: "تصویر بازخورد",
    Nothing: "هیچ یک",
    // UserAppointment.vue User.vue
    madeAnAppointment: "قرار ملاقات با موفقیت انجام شد",
    date: "MM ماه روز DD",
    myFiles: "پروفایل من",
    toFeedback: "به بازخورد بروید",
    // Schedules.vue
    finishedList: "تمام کردن",
    noLiveYet: "هنوز پخش زنده وجود ندارد",
    // Lives.vue Live.vue Reply.vue
    comprehensive: "جامع",
    PremierLeague: "لیگ برتر",
    LaLiga: "لالیگا",
    SerieA: "سری آ",
    Bundesliga: "بوندسلیگا",
    FrenchLeague: "لیگ 1",
    ChineseSuperLeague: "سوپرلیگ چین",
    ChampionsLeague: "لیگ قهرمانان",
    AFCChampionsLeagueCup: "لیگ قهرمانان آسیا",
    follow: "تمرکز بر",
    scan: "آن را اسکن کنید",
    addStreamers: "افزودن دوست لنگر",
    streamerSchedules: "برنامه لنگر",
    videoRecommends: "توصیفات تصویری",
    specialClaims: "بیانیه خاص",
    specialClaimsContent1: "[06live] یک پلتفرم پخش زنده رویدادهای ورزشی است که همه منابع پخش زنده ما توسط اشخاص ثالث ارائه می شوند.",
    specialClaimsContent2: "کاربران اینترنتی لطفا قوانین و مقررات محلی را رعایت کنند و از آن برای مقاصد غیرقانونی استفاده نکنید، لطفاً با ما تماس بگیرید.",
    // Home.vue
    enterLiveRoom: "وارد اتاق پخش زنده شوید",
    hotLiving: "الان داغه",
    checkMore: "بیشتر",
    hotStreamers: "لنگر محبوب",
    footballLiving: "فوتبال زنده",
    basketballLiving: "بسکتبال زنده",
    comprehensiveLiving: "پخش زنده جامع",
    enterLivingRoom: "وارد اتاق پخش زنده شوید",
    // App.vue
    goToScan: "برای دانلود برنامه، کد QR را اسکن کنید",
    adContent1: "در فاصله صفر تعامل داشته باشید و از زمان چت لذت ببرید.",
    adContent2: "صدا بیشتر در مورد دوست یابی است و چت بیش از سرگرمی است.",
    adContent3: "پخش زنده الهه آنلاین، گوشت تازه جوان، دختران ناز، عموهای جذاب",
    adContent4: "24 ساعت شبانه روز! حرفه ای ترین توضیح را برای شما بیاورید.",
    adContent5: "تعداد زیادی برنامه ورزشی فوق العاده واضح که در زمان واقعی انجام می شود",
    adContent6: "تحلیلگران حرفه ای این رویداد را به صورت آنلاین توضیح می دهند.",
    // About.vue
    appIntro1: "06live (06live.tv) یک پلتفرم پخش زنده ورزشی است که به صورت حرفه ای پخش تصویری زنده رویدادهای فوتبال و بسکتبال را ارائه می دهد.",
    appIntro2: "06live متعهد به بهبود مستمر تجربه کاربر، تلاش برای تبدیل شدن به برترین پلتفرم داده های فوتبال و بسکتبال داخلی و کمک به احیای حرفه های فوتبال و بسکتبال داخلی است.",
    appIntro3: "توسط 06live برای شما آورده شده است",
    appIntro4: "سرویس تفسیری ویدیویی محبوب پایدار و پیشرو؛",
    appIntro5: "پخش زنده ویدیویی جامع و با کیفیت بالا از رویدادهای محبوب؛",
    appIntro6: "تفسیر حرفه ای و عینی پخش زنده؛",
    appIntro7: "خدمات داده دقیق و کامل؛",
    // Privacy.vue Disclaimer.vue
    privacyAgreement: "سیاست حفظ حریم خصوصی",
    privacyContent1: "این سیاست حفظ حریم خصوصی، سیاست‌ها و شیوه‌های حریم خصوصی داده‌های شرکت را شرح می‌دهد که نحوه جمع‌آوری، استفاده، پردازش، ذخیره و/یا افشای اطلاعات شخصی شما را که از طریق وب‌سایت‌های شرکت جمع‌آوری شده است، پوشش می‌دهد. لطفا سیاست حفظ حریم خصوصی ما را با دقت بخوانید.",
    privacyContent2: "1. چگونه شرکت ما اطلاعات شخصی شما را جمع آوری می کند",
    privacyContent3: "اطلاعات شخصی داده‌هایی هستند که می‌توان از آنها برای شناسایی یا تماس با یک شخص استفاده کرد.",
    privacyContent4: "هنگامی که از وب سایت ما برای تماشای فیلم های ورزشی استفاده می کنید، ما اطلاعات شخصی شما مانند شماره تلفن را در طی مراحل ثبت نام کاربر جمع آوری می کنیم. به منظور محافظت از حریم خصوصی شخصی، نباید هیچ اطلاعات دیگری ارائه دهید، مگر اینکه به طور خاص توسط شرکت درخواست شود.",
    privacyContent5: "2. نحوه استفاده شرکت ما از اطلاعات شخصی شما",
    privacyContent6: "1. عملکرد بازیابی رمز عبور را از طریق اطلاعات شخصی خود درک کنید.",
    privacyContent7: "2. تا زمانی که شرکت سازماندهی مجدد، ادغام یا فروخته شود، و تمام اطلاعات شخصی که ما جمع آوری می کنیم می تواند به اشخاص ثالث مربوطه منتقل شود، شرکت اطلاعات شخصی شما را به هیچ شخص ثالث غیر مرتبطی ارائه نمی دهد، نمی فروشد، اجاره نمی دهد، به اشتراک نمی گذارد قبلاً از شما اجازه گرفته باشد یا شخص ثالث و شرکت به صورت جداگانه یا مشترک به شما خدمات ارائه می دهند و پس از پایان سرویس از دسترسی به کلیه اطلاعاتی که قبلاً قادر به دسترسی بوده است منع خواهد شد.",
    privacyContent8: "3. امنیت اطلاعات شخصی",
    privacyContent9: "ایمن نگه داشتن اطلاعات شخصی شما برای ما مهم است. هنگامی که شما ثبت نام می کنید و داده های شخصی را در وب سایت ما وارد می کنید، ما این اطلاعات را با استفاده از فناوری لایه سوکت ایمن (SSL) رمزگذاری می کنیم.",
    privacyContent10: "ما از اطلاعاتی که برای ما ارسال می کنید از طریق استانداردهای صنعتی پذیرفته شده عمومی مانند فایروال ها، رمزگذاری و الزامات قانونی حفظ حریم خصوصی داده ها، هم در حین انتقال داده و هم در حین نگهداری داده ها محافظت می کنیم.",
    privacyContent11: "با این حال، هیچ روش انتقال از طریق اینترنت، یا روش ذخیره سازی الکترونیکی 100٪ ایمن نیست. بنابراین، اگرچه ما از ابزارهای قابل قبول تجاری برای محافظت از اطلاعات شخصی شما استفاده می کنیم، نمی توانیم امنیت مطلق آن را تضمین کنیم.",
    privacyContent12: "4. شرکت تا چه زمانی اطلاعات شخصی را حفظ خواهد کرد؟",
    privacyContent13: "به طور کلی، شرکت اطلاعات شخصی شما را تنها تا زمانی که برای تحقق اهدافی که برای آن جمع‌آوری شده است، حفظ می‌کند و از دوره‌های نگهداری داده‌های مورد نیاز توسط قانون قابل اجرا پیروی می‌کند.",
    privacyContent14: "5. سلب مسئولیت قانونی",
    privacyContent15: "ما این حق را برای خود محفوظ می داریم که در مواقعی که قانون به این کار نیاز دارد و شرکت معتقد است افشای اطلاعات مربوط به شما برای محافظت از حقوق قانونی شرکت و/یا پیروی از روندهای قضایی، دستورات دادگاه یا روند قانونی ارائه شده در شرکت ضروری است، اطلاعات را افشا کنیم.",
    privacyContent16: "اگر شرکت تشخیص دهد که افشای آن به طور منطقی برای اجرای شرایط و ضوابط شرکت یا محافظت از عملیات ما ضروری است، ممکن است اطلاعات مربوط به شما را افشا کنیم.",
    privacyContent17: "6. تغییرات در این سیاست حفظ حریم خصوصی",
    privacyContent18: "اگر تصمیم به تغییر سیاست حفظ حریم خصوصی خود داشته باشیم، این تغییرات را در این خط‌مشی، در وب‌سایت خود و در جایی که مناسب بدانیم پست می‌کنیم تا بتوانید بفهمید که چگونه اطلاعات شخصی شما را جمع‌آوری و استفاده می‌کنیم، چه کسی می‌تواند به این اطلاعات دسترسی داشته باشد، و در چه زمانی. ما این اطلاعات را تحت شرایط خاصی افشا خواهیم کرد.",
    privacyContent19: "شرکت حق تغییر این سیاست را در هر زمان برای خود محفوظ می دارد، بنابراین لطفاً آن را مرتباً بررسی کنید. اگر تغییرات عمده ای در این سیاست ایجاد شود، شرکت از طریق اطلاعیه وب سایت شما را مطلع خواهد کرد.",
    disclaimerAndUserAgreement: "توافقنامه کاربر و سلب مسئولیت",
    // h5
    recommend: "توصیه",
    collapse: "بستن",
    unfold: "بسط داد",
    pleaseEnterYourSearch: "لطفاً محتوای جستجوی خود را وارد کنید",
    notFound: "پیدا نشد",
    relatedStreamers: "مجریان مرتبط",
    live: "پخش زنده",
    videoDetail: "جزئیات ویدیو",
    relatedFixtures: "وسایل مرتبط",
    popularSearches: "جستجوهای محبوب",
    searchForATeamStreamer: "جستجو بیم ها و مجریان",
    avatar: "آواتار",
    addAPicture: "اضافه کردن تصاویر",
    addAtMost: "تا 3 عکس اضافه کنید",
    videoCassetteNovideo: "هنوز ویدیویی وجود ندارد",
    loading: "بارگذاری...",
    privateMessageAnchor: "مجری پیام خصوصی",
    noSchedule: "هنوز برنامه ای وجود ندارد",
    chatRoom: "چت روم",
    download: "دانلود",
    tips: "اشاره",
    quickLogin: "ورود سریع",
    passwordLogin: "ورود با رمز عبور",
    loginSuccess: "ورود با موفقیت",
    saveFailed: "ذخیره انجام نشد",
    starAnchor: "لنگر ستاره",
    fans: "پنکه",
    notificationsOff: "اطلاعیه پذیرش بسته شد",
    notificationIsOn: "اطلاعیه پذیرش باز می شود",
    roomMidfield: "خط میانی",
    appTitile: "06 ورزش، تماشای فوتبال و چت",
    downloadTheApp: "دانلود APP",
    recommendedToday: "توصیه امروز",
    systemError: "خطای سیستم، لطفاً بعداً دوباره امتحان کنید",
    released: "زمان انتشار",
    searchContentLive: "مجریان مرتبط",
    information: "بیشتر",
    onlineText: "این اپلیکیشن به زودی راه اندازی می شود، پس با ما همراه باشید...",
    //news
    news:'بیشتر',
    released:'زمان انتشار',
    all:"همه",
    // score
    score: "نمره",
    footScore: "گل های فوتبال",
    basketballScore: "امتیازات بسکتبال",
    liveScore: "امتیاز زنده",
    FinishingScore: "امتیاز پایان",
    FutureSchedule: "برنامه آینده",
    myCollection: "مجموعه من",
    filter: "فیلتر",
    eventFilter: "غربالگری رویداد",
    AllLeagues: "همه لیگ ها",
    FiveMajorLeagues: "پنج لیگ بزرگ",
    firstLeague: "لیگ اول",
    selectAll: "انتخاب همه",
    counterElection: "ضد انتخابات",
    sure: "مطمئن",
    functionSelection: "انتخاب تابع",
    redAndYellow: "نمایش کارت قرمز و زرد",
    show: "نشان می دهد",
    hide: "پنهان شدن",
    league: "لیگ",
    time: "زمان",
    state: "حالت",
    homeTeam: "تیم میزبان",
    score: "نمره",
    awayTeam: "تیم خارج از خانه",
    half: "نیمه وقت",
    cornerKick: "ضربه کرنر",
    victoryOrDefeat: "پیروزی یا شکست",
    handicap: "معلول",
    goalNumber: "تعداد گل های زده شده",
    data: "داده ها",
    descant: "تفسیر",
    live: "پخش زنده",
    operate: "عمل کنند",
    odds: "فهرست مطالب",
    inProgress: "در حال پیش رفت",
    notStarted: "شروع نشده است",
    over: "بر فراز",
    illustration: "تصویر",
    goal: "هدف",
    penaltyKick: "ضربه پنالتی",
    Oolong: "اولانگ",
    redCard: "کارت قرمز",
    yellowCard: "کارت زرد",
    twoYellowsTurnoOneRed: "دو تا زرد یکی قرمز میشه",
    substitution: "تعویض",
    animation: "انیمیشن",
    upper: "برتر",
    lower: "پایین",
    PointsDifference: "تفاوت امتیاز",
    totalScore: "نمره کل",
    handicap: "معلولیت",
    fullTime: "کل مخاطب",
    RealtimeData: "داده های زمان واقعی",
    EuropeOdds: "شاخص اروپایی",
    handicapOdds: "شاخص معلولیت",
    sizeOdds: "شاخص اندازه",
    bookMatch: "یک مسابقه رزرو کنید",
    notBooked: "هنوز هیچ بازی برنامه ریزی شده ای وجود ندارد",
    attack: "حمله کنند",
    dangerAttack: "حمله خطرناک",
    possessionRate: "نرخ مالکیت",
    Shotontarget: "شلیک به هدف",
    Shotofftarget: "شوت از دست رفته",
    textLive: "پیامک پخش زنده",
    allShow: "نمایش همه",
    companies: "بین شرکتی",
    immediate: "فوری",
    initial: "اولیه",
    timeChanges: "تغییر زمان",
    allCompanies: "همه شرکت ها",
    homeWin: "پیروزی اصلی",
    awayWin: "کشنگ",
    draw: "قرعه کشی",
    maxBall: "توپ بزرگ",
    minBall: "توپ کوچک",
    Sunday: "یکشنبه",
    Monday: "در روز دوشنبه",
    Tuesday: "سه‌شنبه",
    Wednesday: "چهارشنبه",
    Thursday: "پنجشنبه",
    Friday: "جمعه",
    Saturday: "شنبه",
    privateMessages: "نامه خصوصی",
    setUp: "برپایی",
    RankingDis: "نمایش رتبه بندی",
    homeawayLogo: "شناسایی میزبان و مهمان",
    all: "همه",
    hot: "محبوب",
    noData: "اطلاعاتی وجود ندارد",
    missedPenalty: "پنالتی از دست رفته",
    noData: "اطلاعاتی وجود ندارد",
};
