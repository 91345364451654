import Vue from 'vue'
import Vuex from 'vuex'
import api from '../api'
Vue.use(Vuex)

const TYPES = {
	'TOKEN': 'TOKEN',
	'USER_INFO': 'USER_INFO',
	'MEMBER_INFO': 'MEMBER_INFO',
	'APPOINTMENTS': 'APPOINTMENTS',
	'LOADING': 'LOADING',
	'DOWNLOAD_URL': 'DOWNLOAD_URL',
	'CHANGE_SCORE_LANGUAGE': 'CHANGE_SCORE_LANGUAGE',
	'SHOW_SCORE_CARD': 'SHOW_SCORE_CARD',
	'TEMPORARY_TOKEN': 'TEMPORARY_TOKEN',
	'TEMPORARY_USER': 'TEMPORARY_USER',
	'TEMPORARY_MIDS': 'TEMPORARY_MIDS'
}
export default new Vuex.Store({
	state: {
		token: '',
		minfo: {},
		appointments: [],

		loading: false,


		iosUrl: "",
		androidUrl: "",
		temporaryToken: "",
		temporaryUser: {},
		temporaryChat: {},
		temporaryMids: {},
		scoreLanguage: 'zh_CN',
		scoreCardShow: true
	},
	mutations: {
		[TYPES.TOKEN](state, token) {
			state.token = token
		},
		[TYPES.MEMBER_INFO](state, minfo) {
			state.minfo = minfo
		},
		[TYPES.APPOINTMENTS](state, appointments) {
			state.appointments = appointments
		},
		[TYPES.LOADING](state, loading) {
			state.loading = loading
		},
		[TYPES.DOWNLOAD_URL](state, {
			iosUrl,
			androidUrl
		}) {
			state.iosUrl = iosUrl
			state.androidUrl = androidUrl
		},
		[TYPES.TEMPORARY_TOKEN](state, token = '') {
			state.temporaryToken = token
		},
		[TYPES.TEMPORARY_USER](state, { minfo, chat }) {
			state.temporaryUser = minfo
			state.temporaryChat = chat
		},
		[TYPES.TEMPORARY_MIDS](state, data) {
			Object.assign(state.temporaryMids, data)
		},
		[TYPES.CHANGE_SCORE_LANGUAGE](state, data) {
			console.log(data);
			state.scoreLanguage = data
		},
		[TYPES.SHOW_SCORE_CARD](state, data) {
			console.log('TYPES.SHOW_SCORE_CARD', data)
			state.scoreCardShow = data
		},
	},
	actions: {
		async setScoreLanguage(context, language) {
			context.commit(TYPES.CHANGE_SCORE_LANGUAGE, language.target.value)
		},
		async changeScoreCardShow(context, show) {
			console.log('changeScoreCardShow', show)
			context.commit(TYPES.SHOW_SCORE_CARD, show.target.value)
		},
		async setToken(context, token = false) {
			context.commit(TYPES.TOKEN, token)
			if (token) {
				context.dispatch('getMemberInfo')
			}
		},
		async getMemberInfo(context) {
			const response = await api.get('/get_user')
			if (response && response.code == 200) {
				context.commit(TYPES.MEMBER_INFO, response.data)
				return response.data
			}
			return Promise.reject(false)
		},
		async setMemberInfo(context, minfo = {}) {
			context.commit(TYPES.MEMBER_INFO, minfo)
		},
		async syncMemberInfo(context) {
			if (context.state.minfo.uid) {
				return context.state.minfo
			}
			return context.dispatch('getMemberInfo')
		},
		async getAppointments(context) {
			const response = await api.get('/web/appointment/list')
			if (response) {
				const appointments = []
				Object.keys(response.data).forEach(date => {
					appointments.push({
						date,
						lists: response.data[date]
					})
				})
				context.commit(TYPES.APPOINTMENTS, appointments)
			}
		},
		async setLoading(context, show) {
			context.commit(TYPES.LOADING, show)
		},
		async getDownloadUrls(context) {
			const response = await api.get('/web/download')
			if (response.code == 200) {
				const {
					android_url,
					ios_url
				} = response.data
				context.commit(TYPES.DOWNLOAD_URL, {
					iosUrl: ios_url,
					androidUrl: android_url
				})
			}
			return response.data
		}
	}
})
