<template>
	<header class="header">
		<div class="wrapper">
			<nav class="user" v-if="minfo.uid">
				<a href="/anchor/" class="login" target="_blank"
					v-if="minfo && minfo.role && (minfo.role == 1 && minfo.uid == 133 || [3, 9].includes(minfo.role))">
					{{ $t("startLive") }}
				</a>
				<div class="userinfo">
					<img class="avatar" :src="minfo.avatar" :alt="minfo.nickname" @error="onAvatarError" />
					<span>{{ minfo.nickname }}</span>

					<div class="userinfo-popover">
						<div class="userinfo-popover-content">
							<div class="info">
								<img class="avatar" :src="minfo.avatar" :alt="minfo.nickname" @error="onAvatarError" />
								<span class="name">{{ minfo.nickname }}</span>
								<a href="javascript:;" class="button" @click="onLogout">{{ $t("logout") }}</a>
							</div>
							<div class="sign">{{ minfo.sign }}</div>
							<div class="tabs">
								<router-link to="/user/info" class="tabs-item icon-user">{{ $t("personalCenter")
								}}</router-link>
								<router-link to="/user/follow" class="tabs-item icon-follow">{{ $t("myFollows")
								}}</router-link>
								<router-link to="/user/appointment" class="tabs-item icon-appointment">{{
									$t("myAppointments") }}</router-link>
								<router-link to="/user/feedback" class="tabs-item icon-feedback">{{ $t("myFeedbacks")
								}}</router-link>
							</div>
						</div>
					</div>
				</div>
			</nav>
			<nav class="user" v-else>
				<a href="javascript:;" class="login-bt" @click="toLogin">{{ $t("login") }}</a>
				<a href="javascript:;" class="register" @click="toRegister">{{ $t("register") }}</a>
			</nav>
		
			<nav class="user" v-if="isDev" @click="changeLocale" style="color:red;flex:1">
				<ws-icon></ws-icon>
				语言切换
			</nav>
			<div style="flex: 1;"></div>
			<nav class="menu">
				<a :class="[routeName == 7 ? 'active' : '']" href="/news">{{ $t("news") }}</a>
				<a :class="[activeNav == 4 ? 'active' : '']" href="/schedules">{{ $t("schedules") }}</a>
				<a :class="[activeNav == 3 ? 'active' : '']" href="/lives/1">{{ $t("allLive") }}</a>
				<a :class="[activeNav == 1 ? 'active' : '']" href="/"> {{ $t("frontPage") }}</a>
				<!-- <a-dropdown @visibleChange="visibleChange">
					<a class="ant-dropdown-link" :class="[activeNav == 201 || activeNav == 202 ? 'active' : '']"
						@click="e => e.preventDefault()">{{ $t(scoreTitle) }} <a-icon :type="dropdownIcon"
							style="font-size:14px" />
					</a>
					<a-menu slot="overlay">
						<a-menu-item>
							<a href="/score/football" @click="scoreTitle = 'footScore'">{{ $t("footScore")}}</a>
						</a-menu-item>
						<a-menu-item>
							<a href="/score/basketball" @click="scoreTitle = 'basketballScore'">{{ $t("basketballScore")}}</a>
						</a-menu-item>
					</a-menu>
				</a-dropdown> -->
	
		
		
				<!-- <a :class="[routeName == 8 ? 'active' : '']" href="/app">{{ $t("app") }}</a> -->
			</nav>



			<a href="/" class="logo"><img src="@/assets/img/logo.png" /></a>
		</div>
		<ws-authorize :type.sync="modal.type" v-model="modal.visible"></ws-authorize>
	</header>
</template>

<script>
import { mapState } from 'vuex';
import wsAuthorize from '@/components/wsAuthorize.vue';
import wsIcon from '@/components/wsIcon.vue';
import { AUTHORIZE_TYPE } from '@/utils/constants.js';
import { Modal } from 'ant-design-vue';

export default {
	name: 'wsHeader',
	components: {
		wsAuthorize,
		wsIcon
	},
	data() {
		return {
			AUTHORIZE_TYPE,
			modal: {
				type: AUTHORIZE_TYPE.LOGIN_PASSWORD,
				visible: false
			},
			scoreTitle: 'score',
			dropdownIcon: 'down'
		};
	},
	computed: {
		...mapState(['minfo']),
		routeName() {
			return this.$route.name.toLowerCase();
		},
		activeNav() {
			const routeName = this.$route.name;
			console.log(this.$route.name);
			if (routeName == 'Home') {
				return 1;
			} else if (['score', 'completed', 'schedule', 'collect'].includes(routeName)) {
				if (this.$route.params.type == 'football') {
					this.scoreTitle = 'footScore'
					return 201;
				}
				if (this.$route.params.type == 'basketball') {
					this.scoreTitle = 'basketballScore'
					return 202;
				}
			} else if (routeName == 'Lives') {
				return 3;
			} else if (routeName == 'Schedules') {
				return 4;
			} else if (/playback/i.test(routeName)) {
				return 5;
			} else if (/video/i.test(routeName)) {
				return 6;
			} else if (/News/i.test(routeName)) {
				return 7;
			} else if (/App/i.test(routeName)) {
				return 8;
			}
		},
	},
	created() {
		this.$bus.$on('login', this.toLogin);
	},
	methods: {
		visibleChange(visible) {
			if (visible) {
				this.dropdownIcon = 'up'
			} else {
				this.dropdownIcon = 'down'
			}
		},
		changeLocale() {
			this.$i18n.locale == 'zh' ? this.$i18n.locale = 'vi' : this.$i18n.locale = 'zh'
		},
		toLogin() {
			this.modal.type = AUTHORIZE_TYPE.LOGIN_PASSWORD;
			this.modal.visible = true;
		},
		toRegister() {
			this.modal.type = AUTHORIZE_TYPE.REGISTER;
			this.modal.visible = true;
		},
		onLogout() {
			this.$modal.confirm({
				title: this.$t("logoutConfirm"),
				okType: 'danger',
				okText: this.$t("signOff"),
				cancelText: this.$t("cancel"),
				onOk: async () => {
					await this.$api.post('/web/login_out');
					this.$storage.clear();
					location.href = '/';
				}
			});
		}
	}
};
</script>

<style lang="less"></style>
