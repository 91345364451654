<template>
	<a-config-provider :locale="locale">
		<div id="app">
			<router-view />
			<ws-loading v-if="$store.state.loading"></ws-loading>
		</div>
	</a-config-provider>
</template>

<script type="text/javascript">
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import wsLoading from '@/components/wsLoading.vue';

export default {
	components: {
		wsLoading
	},
	data() {
		return {
			locale: zhCN
		};
	}
};
</script>

<style lang="less">
@import '~ant-design-vue/dist/antd.less';
@import url('~@/assets/less/common.less');	


.ant-form-item {
  display: flex;
  flex-direction: row-reverse;
}
.ant-form-item-label{
	text-align: left; /* 确保 label 内容左对齐 */
	margin-left: 8px; /* 调整 label 和输入框之间的距离 */
}
.ant-form-item-label > label::after {
    content: '';
}
.ant-form-item-label > label::before {
    content: ':';
    position: relative;
    top: -0.5px;
    margin: 0 8px 0 2px;
}
:root {
  --primary-color: #ffc927;
  --active-bgcolor: #ffc927;
}
</style>